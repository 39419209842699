// РЕАЛИЗАЦИЯ ПЛАВНОЙ ПРОКРУТКИ К ССЫЛКАМ-ЯКОРЯМ БЕЗ JQUERY
// ===== START =====
// var linkNav = document.querySelectorAll('[href^="#"]'), //выбираем все ссылки к якорю на странице
//     V = 0.2;  // скорость, может иметь дробное значение через точку (чем меньше значение - тем больше скорость)
// for (var i = 0; i < linkNav.length; i++) {
//     linkNav[i].addEventListener('click', function(e) { //по клику на ссылку
//         e.preventDefault(); //отменяем стандартное поведение
//         var w = window.pageYOffset,  // производим прокрутка прокрутка
//             hash = this.href.replace(/[^#]*(.*)/, '$1');  // к id элемента, к которому нужно перейти
//         t = document.querySelector(hash).getBoundingClientRect().top,  // отступ от окна браузера до id
//             start = null;
//         requestAnimationFrame(step);  // подробнее про функцию анимации [developer.mozilla.org]
//         function step(time) {
//             if (start === null) start = time;
//             var progress = time - start,
//                 r = (t < 0 ? Math.max(w - progress/V, w + t) : Math.min(w + progress/V, w + t));
//             window.scrollTo(0,r);
//             if (r != w + t) {
//                 requestAnimationFrame(step)
//             } else {
//                 location.hash = hash  // URL с хэшем
//             }
//         }
//     }, false);
// }
// ===== END =====


// BACK TO TOP BUTTON БЕЗ JQUERY
// ===== START =====
// (function() {
//   'use strict';

//   function trackScroll() {
//     var scrolled = window.pageYOffset;
//     var coords = document.documentElement.clientHeight;

//     if (scrolled > coords) {
//       goTopBtn.classList.add('back-to-top_show');
//     }
//     if (scrolled < coords) {
//       goTopBtn.classList.remove('back-to-top_show');
//     }
//   }

//   function backToTop() {
//     if (window.pageYOffset > 0) {
//       window.scrollBy(0, -30);
//       setTimeout(backToTop, 0);
//     }
//   }

//   var goTopBtn = document.querySelector('.back-to-top');

//   window.addEventListener('scroll', trackScroll);
//   goTopBtn.addEventListener('click', backToTop);
// })();
// ===== END =====


$(document).ready(function() {
  $('.information-block').addClass("hidden");

  $('#gallery1 .gallery__item').simpleLightbox();
  $('#gallery2 .gallery__item').simpleLightbox();
  $('#gallery3 .gallery__item').simpleLightbox();
  $('#gallery4 .gallery__item').simpleLightbox();
  $('#gallery5 .gallery__item').simpleLightbox();
  $('#gallery6 .gallery__item').simpleLightbox();
});




function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return (elemTop < docViewBottom);
}

$(window).scroll(function() {
  var informationBlocks = document.querySelectorAll('.information-block');
  for (var i = 0; i < informationBlocks.length; i++) {
    if ($(informationBlocks[i]).hasClass("hidden")) {
      if (isScrolledIntoView(informationBlocks[i])) {
        $(informationBlocks[i]).removeClass("hidden");
        $(informationBlocks[i]).addClass("visible animate__animated animate__fadeInUp");
      }
    }
  }
});

// РЕАЛИЗАЦИЯ ПЛАВНОЙ ПРОКРУТКИ К ССЫЛКАМ-ЯКОРЯМ НА JQUERY
// ===== START =====
$(function() {
  $("a[href^='#']").click(function() {
    var _href = $(this).attr("href");
    $("html, body").animate({scrollTop: $(_href).offset().top+"px"});
    return false;
  });
});
// ===== END =====


// BACK TO TOP BUTTON НА JQUERY
// ===== START =====
$(window).scroll(function () {
  if ($(this).scrollTop() > 100) {
    $('.back-to-top').fadeIn();
  } else {
    $('.back-to-top').fadeOut();
  }
});
$('.back-to-top').click(function () {
  $('body, html').animate({scrollTop: 0}, 400);
  return false;
});
// ===== END =====


// РЕАЛИЗАЦИЯ КНОПКИ "МЕНЮ" ДЛЯ МАЛЕНЬКИХ ЭКРАНОВ
// ===== START =====
$(function() {
  $('#navButton').click(function() {
    $('.main-nav').toggle();
    $('#navButton').toggleClass('active');
    return false;
  });
});
// ===== END =====

// MENU
// ===== START =====
$(function () {
  var location = window.location.href;
  var cur_url = location.split('/').pop();

  if (!cur_url) cur_url = '/';

  $('.main-nav .main-nav_item').each(function () {
    var link = $(this).attr('href').split('\\').pop();

    if (cur_url === link) {
      $(this).addClass('active');
    }
  });
});

// $('#navItemAbout').mouseover(function () {
//   $('#subnavItemAbout').addClass('visible');
// });
// $('#navItemAbout').mouseout(function () {
//   $('#subnavItemAbout').removeClass('visible');
// });
// $('#subnavItemAbout').mouseover(function () {
//   $('#subnavItemAbout').addClass('visible');
// });
// $('#subnavItemAbout').mouseout(function () {
//   $('#subnavItemAbout').removeClass('visible');
// });
// ===== END =====


// // MENU-FILTER
// // ===== START =====
// $('.project-menu_item').click(function () {
//   clickMoreButtonCount = 0;

//   $('.project-menu_item').each(function () {
//     $(this).removeClass('active');
//   });
//   $(this).addClass('active');

//   const id = $(this).attr('id');
//   projectsVisibled(id);

//   return false;
// });
// // ===== END =====

// // РЕАЛИЗАЦИЯ КНОПКИ "ЕЩЕ" ДЛЯ ПРОЕКТОВ
// // ===== START =====
// var clickMoreButtonCount = 0;
// $('#moreButton').click(function () {
//   clickMoreButtonCount++;
//   const id = $('.project-menu_item.active').attr('id');
//   projectsVisibled(id);
// });

// // FIRST LOAD PROJECT ITEMS
// $(document).ready(function () {
//   visibleItemCount = 0;
//   $('.project_item').each(function () {
//     visibleItemCount++;
//     if (visibleItemCount <= 4) return;
//     $(this).fadeOut();
//     $('#moreButton').fadeIn();
//   });
// })

// function projectsVisibled(projectId) {
//   if (projectId === 'projectsAll' && $('.project_item').length > 4) $('#moreButton').fadeIn();
//   if (projectId !== 'projectsAll' && $('[data-project="' + projectId + '"]').length > 4) $('#moreButton').fadeIn();

//   let visibleItemCount = 0;
//   $('.project_item').each(function () {
//     if (visibleItemCount >= 4 * (clickMoreButtonCount + 1)) {
//       $(this).fadeOut();
//       return;
//     }

//     if (projectId === 'projectsAll') {
//       $(this).fadeIn();
//       visibleItemCount++;
//       if (visibleItemCount === $('.project_item').length) $('#moreButton').fadeOut();
//       return;
//     }

//     const data = $(this).attr('data-project');
//     if (projectId === data) {
//       $(this).fadeIn();
//       visibleItemCount++;
//       if (visibleItemCount === $('[data-project="' + data + '"]').length) $('#moreButton').fadeOut();
//     } else $(this).fadeOut();
//   });
// }
// ===== END =====
